


@font-face {
  font-family: 'Newsreader';
  src: url('./styles/Newsreader-VariableFont_opsz\,wght.ttf') format('truetype');
  font-weight: 1 999; /* Set weight range for variable font */
  font-style: normal;
}
@font-face {
  font-family: 'Newsreader';
  src: url('./styles/Newsreader-Italic-VariableFont_opsz\,wght.ttf') format('truetype');
  font-weight: 1 999; /* Set weight range for variable font */
  font-style: italic;
}

@font-face {
  font-family: 'Spartan';
  src: url('./styles/LeagueSpartan-VariableFont_wght.ttf') format('truetype');
  font-weight: 1 999; /* Set weight range for variable font */
  font-style: normal;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Spartan', sans-serif;
}

h1, h2, h3 {
  font-family: 'Spartan', sans-serif;
}

code {
  font-family: 'Spartan', sans-serif;
}

@media (max-width: 959px) {
    .mobile-hide {
        display: none;
    }
}

@media (min-width: 960px) {
    .non-mobile-hide {
        display: none;
    }
}