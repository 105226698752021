.conversationTitle {
  display: flex;
  flex-direction: row;
  width: 300px;
  z-index: 3;
  padding: 20px 12px;
  border-top: 1px solid #EFEFEF; /* Example color definition */
  border-bottom: 1px solid #EFEFEF; /* Example color definition */
  font-weight: 700;
  font-size: 16px; /* Default font size for non-mobile */

  /* Media query for screens up to 959px (mobile styles) */
  @media (max-width: 959px) {
    font-size: 13px; /* Font size for mobile */
  }
}